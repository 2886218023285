import React from 'react';
import styled from "styled-components";

export const Contact = () => {


  return (
    <SContainer>
      <STitle>Contact</STitle>
      <SCommentContainer>
      <SCommentLeft>御用の際は下記メールアドレスまたは各種SNSのDMまでご連絡ください。
      <br/>＊2025年1月から同3月まで受注は停止いたしております。ご了承ください。</SCommentLeft>
        <SComment>nautisshippolab [at] gmail.com</SComment>
        <SCommentLeft>制作のご依頼・ご相談の際は、下記の項目を事前にお知らせいただけますとスムーズです。</SCommentLeft>
        <SInformationContainer>
          <SCommentLeft>
            【お伝えいただきたいこと】<br />
            <br />
            １．ご連絡先<br />
            お名前、一般/法人・個人事業主、会社名・屋号、メールアドレス、電話番号、ホームページなど<br />
            <br />
            ２．制作内容<br />
            使用媒体・使用期間・使用目的（営利/非営利）など<br />
            <br />
            ３．制作仕様<br />
            点数・サイズ・納品形式など<br />
            <br />
            ４．ご希望納期<br />
            <br />
            ５．ご予算<br />
            未定の場合、詳しい内容をお知らせいただけましたらお見積り可能です。<br />
            <br />
            ６．お問合せ内容詳細<br />
          </SCommentLeft>
        </SInformationContainer>
        <SCommentLeft>
          内容を確認後、改めてこちらから折り返しご連絡いたします。<br />
          制作についてのやり取りは基本的にメールかDMで行い、必要に応じてお電話やリモート会議でのご対応も可能です。<br />
          よろしくお願い申し上げます。
        </SCommentLeft>
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
`

const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
const SCommentLeft = styled(SComment)`
  text-align: left;
`
const SInformationContainer = styled.div`
  margin: 36px auto 12px;
  width: 80%;
  padding: 0px 24px 24px 24px;
  border: solid 1px;
  border-radius: 50px;
`